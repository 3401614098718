import { shallowEqual, useSelector } from 'react-redux'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { MerchantPaneWrapper, CoreButton, SelectionElement, PaymentWrapper } from '../../../components'

import { findCurrencyPrecision, formatFloat, tr } from 'mmfintech-commons'

export const WalletCheckoutPreview = ({ selectedAccount, handlePay, onCancel }) => {
  const { session, currencies, walletPreview, walletPayError, walletPayFetching } = useSelector(
    ({ checkout: { logo, session, walletPreview, walletPayError, walletPayFetching }, common: { currencies } }) => ({
      logo,
      session,
      currencies,
      walletPreview,
      walletPayError,
      walletPayFetching
    }),
    shallowEqual
  )

  const { merchantName } = session || {}
  const { walletAmount, walletCurrency } = walletPreview || {}

  return (
    <MerchantPaneWrapper>
      <PaymentWrapper>
        <div className='merchant-pane-body-container'>
          <span className='subtitle'>Review & Pay</span>

          <div className='merchant-pane-body-wrapper'>
            <div className='merchant-pane-details-wrapper'>
              <span className='merchant-pane-details-title'>{tr('CHECKOUT.PAYMENT.PAY_TO', 'Pay to')}</span>
              <span className='merchant-pane-details-value'>{merchantName}</span>
            </div>
            <div className='merchant-pane-details-wrapper'>
              <span className='merchant-pane-details-title'>{tr('CHECKOUT.PAYMENT.FROM_ACCOUNT', 'From account')}</span>
              <SelectionElement type='account' data={selectedAccount} />
            </div>
            {walletAmount ? (
              <div className='merchant-pane-details-wrapper'>
                <span className='merchant-pane-details-title'>
                  {tr('CHECKOUT.PAYMENT.WALLET_AMOUNT', 'Wallet amount')}
                </span>
                <span className='merchant-pane-details-value'>
                  <span className='amount'>
                    {formatFloat(walletAmount, findCurrencyPrecision(walletCurrency, currencies))}
                  </span>
                  <span className='currency'>{walletCurrency}</span>
                </span>
              </div>
            ) : (
              <div className='merchant-pane-details-wrapper'>
                <span className='merchant-pane-details-title'>{tr('CHECKOUT.PAYMENT.CURRENCY', 'Currency')}</span>
                <span className='merchant-pane-details-value'>{walletCurrency}</span>
              </div>
            )}
          </div>
          <ErrorDisplay error={walletPayError} />
        </div>
        <div className='merchant-pane-buttons'>
          <CoreButton
            type='button'
            variation='primary'
            text={tr('CHECKOUT.BUTTONS.PAY', 'Pay')}
            fullWidth
            onClick={handlePay}
            isLoading={walletPayFetching}
            data-test='button-submit'
          />
          <CoreButton
            type='button'
            variation='tertiary'
            text={tr('CHECKOUT.BUTTONS.CANCEL', 'Cancel')}
            disabled={walletPayFetching}
            onClick={onCancel}
            data-test='button-back'
          />
        </div>
      </PaymentWrapper>
    </MerchantPaneWrapper>
  )
}
